@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
    --fontFamily: 'Lato', sans-serif;
}

.Toastify__toast {
    font-family: var(--fontFamily);
}

* {
    margin: 0;
    padding: 0;
}

html {
    font-family: var(--fontFamily);
    -webkit-font-smoothing: unset !important;
    overflow-y: scroll;
    overflow-y: overlay;
}

.class1 {
    color: blue;
}

.maker-label {
    color: red;
}

.gm-style-cc {
    display: none;
}

.gm-ui-hover-effect {
    outline: none;
}

.info-box-class {
    transform: translateZ(0px) translate(-50%, -50%) !important;
    cursor: pointer;
}

.Toastify__toast-body {
    padding-top: 0;
    padding-bottom: 0;
}

.Toastify__toast--error {
    background-color: rgb(252, 231, 228);
    color: #e01b00;
    border-left: 2px solid #e01b00;
    position: relative;
}

/* .Toastify__toast--error::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: white;
} */

.Toastify__toast--success {
    background-color: rgba(232, 245, 233, 1);
    color: #009d4f;
    border-left: 2px solid #009d4f;
}

.Toastify__toast--warning {
    background-color: rgb(253 239 205);
    color: #ff8b00;
    border-left: 2px solid #ff8b00;
}

.Toastify__toast {
    min-height: 56px;
}

/* width */
.styled-scroll::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.styled-scroll::-webkit-scrollbar-track {
    /* background: #f1f1f178; */
}

/* Handle */
.styled-scroll::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 5px;
}

/* Handle on hover */
.styled-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}
